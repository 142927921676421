import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {IdentityService} from '../../../business/users/core/identity.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthRedirectService} from '../../../business/users/core/auth-redirect.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(private readonly router: Router,
                private readonly identityService: IdentityService,
                private readonly redirectService: AuthRedirectService,
                private readonly authRedirectService: AuthRedirectService,
                @Inject(PLATFORM_ID) private readonly platformId: object) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<true | UrlTree> {
        const url: string = state.url;
        const customerId = route.queryParams["customer_id"];
        return this.checkIsAuthenticated(url, customerId);
    }

    public canLoad(route: Route): Observable<true | UrlTree> {
        const url: string = `/${route.path}`;
        return this.checkIsAuthenticated(url);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<true | UrlTree> {
        return this.canActivate(route, state);
    }

    private checkIsAuthenticated(url: string, customerId?: string): Observable<true | UrlTree> {
        const user$ = this.identityService.stateChanges.pipe(take(1), map(authState => authState.user));

        return user$
            .pipe(
                map((user) => {
                    if (!!user) return true;
                    this.redirectService.setAfterLoginRedirectUrl(url);
                    this.authRedirectService.setCustomerId(customerId);
                    return this.router.createUrlTree(['/login']);
                }),
            );
    }
}
